// Using the proposed JSON type mentioned in this comment:
// https://github.com/microsoft/TypeScript/issues/1897#issuecomment-822032151
export type JSONValue =
  | string
  | number
  | boolean
  | null
  | JSONValue[]
  | { [key: string]: JSONValue }

// We tolerate undefined values in JSON objects built in Javascript
export type JavascriptObject =
  | string
  | number
  | boolean
  | null
  | undefined
  | JavascriptObject[]
  | { [key: string]: JavascriptObject }

export const toCamelCase = (str: string) =>
  str.replace(/([a-z])_([a-z])/g, (m, g1, g2) => g1 + g2.toUpperCase())

const camelizeKeys = (obj: JSONValue): JSONValue => {
  if (
    obj === null ||
    obj === undefined ||
    obj instanceof Date ||
    typeof obj === "string" ||
    typeof obj === "number" ||
    typeof obj === "boolean"
  ) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(i => camelizeKeys(i))
  }

  const n: JSONValue = {}
  Object.keys(obj).forEach(k => {
    n[toCamelCase(k)] = camelizeKeys(obj[k])
  })
  return n
}

const to_snake_case = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase()

export const snakize_keys = (obj: JavascriptObject): JavascriptObject => {
  if (
    obj === null ||
    obj === undefined ||
    obj instanceof Date ||
    typeof obj === "string" ||
    typeof obj === "number" ||
    typeof obj === "boolean"
  ) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(i => snakize_keys(i))
  }

  const n: JavascriptObject = {}
  Object.keys(obj).forEach(k => {
    n[to_snake_case(k)] = snakize_keys(obj[k])
  })
  return n
}

export const encode = (toEncode: JavascriptObject) => JSON.stringify(snakize_keys(toEncode))

export const decode = (toDecode: string) => camelizeKeys(JSON.parse(toDecode))
