import { useAuth0 } from "@auth0/auth0-react"
import { captureException } from "@sentry/browser"

export const useLogout = () => {
  const { logout } = useAuth0()
  return () => {
    try {
      sessionStorage.clear()
      localStorage.clear()
    } catch (e) {
      captureException(e)
    }
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }
}
