import { IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, Transition } from "@headlessui/react"
import clsx from "clsx"
import { Fragment, memo, ReactNode, MutableRefObject, Suspense } from "react"
import Loader from "src/components/base/misc/Loader"

const colorToTheme = {
  danger: {
    bg100: "bg-red-100",
    bg600: "bg-red-600",
    focusRing500: "focus:ring-red-500",
    hoverBg700: "hover:bg-red-700",
    text600: "text-red-600",
  },
  primary: {
    bg100: "bg-blue-100",
    bg600: "bg-blue-600",
    focusRing500: "focus:ring-blue-500",
    hoverBg700: "hover:bg-blue-700",
    text600: "text-blue-600",
  },
  success: {
    bg100: "bg-green-100",
    bg600: "bg-green-600",
    focusRing500: "focus:ring-green-500",
    hoverBg700: "hover:bg-green-700",
    text600: "text-green-600",
  },
  warning: {
    bg100: "bg-orange-100",
    bg600: "bg-orange-600",
    focusRing500: "focus:ring-orange-500",
    hoverBg700: "hover:bg-orange-700",
    text600: "text-orange-600",
  },
}

type Props = {
  children: ReactNode
  color: keyof typeof colorToTheme
  icon: IconDefinition
  initialFocus?: MutableRefObject<HTMLElement | null>
  show: boolean
  onDismiss: () => void
}

const Modal = (props: Props) => {
  const { children, color, icon, initialFocus, onDismiss, show } = props

  return (
    <Transition.Root appear as={Fragment} show={show}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={initialFocus}
        onClose={onDismiss}
      >
        <div className="flex min-h-screen flex-col items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div aria-hidden="true" className="hidden sm:block sm:flex-grow-[1]" />

          <Suspense
            fallback={
              <div className="relative transform px-4 pt-5 pb-4 transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Loader isModal />
              </div>
            }
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={clsx(
                      "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
                      colorToTheme[color].bg100
                    )}
                  >
                    <FontAwesomeIcon
                      aria-hidden="true"
                      className={clsx("h-5 w-5", colorToTheme[color].text600)}
                      icon={icon}
                    />
                  </div>
                  <div className="mt-3 grow text-center sm:mt-0 sm:ml-4 sm:text-left">
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Suspense>

          <div aria-hidden="true" className="hidden sm:block sm:flex-grow-[2]" />
        </div>
      </Dialog>
    </Transition.Root>
  )
}

/**
 * Stateless modal component.
 *
 * **Important**: This component renders its content only when it's open (`show=true`).
 * To avoid triggering API request when modals are not displayed, use a separate component for
 * modal content.
 */
export default memo(Modal)
