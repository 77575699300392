import { faBuildings, faWater } from "@fortawesome/pro-regular-svg-icons"
import { useMatchRoute } from "@tanstack/react-location-lite-experimental"
import { HTMLAttributes } from "react"
import { useMatchesParams } from "src/hooks/router"
import { useAdminUser } from "src/queries/AdminUsers"
import { useApiKey, useOrganization } from "src/queries/Organizations"
import { usePool } from "src/queries/Pools"
import { useUser } from "src/queries/Users"
import Skeleton from "./Skeleton"
import Title from "./Title"

type Props = HTMLAttributes<HTMLDivElement>

const PageTitle = ({ ...attrs }: Props) => {
  const matchRoute = useMatchRoute()

  if (matchRoute({ to: "/" }) || matchRoute({ to: "/organizations" })) {
    return <Title title="Organizations" {...attrs} />
  }

  if (matchRoute({ to: "/admin-users" })) {
    return <Title title="Admin users" {...attrs} />
  }

  if (matchRoute({ to: "/admin-users/:adminUserUuid" })) {
    return <AdminUserTitle {...attrs} />
  }

  if (matchRoute({ to: "/billing" })) {
    return <Title title="Billing accounts" {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:organizationUuid" })) {
    return <OrganizationTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:organizationUuid/api-keys/:apiKeyUuid" })) {
    return <OrganizationApiKeyTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:organizationUuid/billing" })) {
    return <BillingAccountTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:organizationUuid/pools/:poolUuid/api-keys/:apiKeyUuid" })) {
    return <PoolApiKeyTitle {...attrs} />
  }

  if (matchRoute({ fuzzy: true, to: "/organizations/:organizationUuid/pools" })) {
    return <PoolTitle {...attrs} />
  }

  if (matchRoute({ to: "/trial-credit" })) {
    return <Title title="Trial credits" {...attrs} />
  }

  if (matchRoute({ to: "/users" })) {
    return <Title title="Users" {...attrs} />
  }

  if (matchRoute({ to: "/users/:userUuid" })) {
    return <UserTitle {...attrs} />
  }

  if (matchRoute({ to: "/waiting-list" })) {
    return <Title title="Waiting list" {...attrs} />
  }

  return <Title {...attrs} />
}

export default PageTitle

const AdminUserTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const adminUserQuery = useAdminUser({ enabled: !!params.adminUserUuid, params, suspense: false })
  if (adminUserQuery.isInitialLoading) return <Skeleton />
  return <Title title={adminUserQuery.data?.name} uuid={params.adminUserUuid} {...attrs} />
}

const OrganizationApiKeyTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useOrganization({ enabled: !!params.organizationUuid, params, suspense: false })
  const apiKeyQuery = useApiKey({ enabled: !!params.apiKeyUuid, params, suspense: false })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.organizationUuid}`,
  }
  if (orgQuery.isInitialLoading || apiKeyQuery.isInitialLoading) return <Skeleton />
  return (
    <Title parent={parent} title={apiKeyQuery.data?.name} uuid={params.apiKeyUuid} {...attrs} />
  )
}

const BillingAccountTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useOrganization({ enabled: !!params.organizationUuid, params, suspense: false })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.organizationUuid}`,
  }
  if (orgQuery.isInitialLoading) return <Skeleton />
  return <Title parent={parent} title="Billing account" {...attrs} />
}

const OrganizationTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useOrganization({ enabled: !!params.organizationUuid, params, suspense: false })
  if (orgQuery.isInitialLoading) return <Skeleton />
  return <Title title={orgQuery.data?.name} uuid={params.organizationUuid} {...attrs} />
}

const PoolTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useOrganization({ enabled: !!params.organizationUuid, params, suspense: false })
  const poolQuery = usePool({ enabled: !!params.poolUuid, params, suspense: false })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.organizationUuid}`,
  }
  if (orgQuery.isInitialLoading || poolQuery.isInitialLoading) return <Skeleton />
  return (
    <Title parent={parent} title={poolQuery.data?.name} uuid={params.organizationUuid} {...attrs} />
  )
}

const PoolApiKeyTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const poolQuery = usePool({ enabled: !!params.poolUuid, params, suspense: false })
  const apiKeyQuery = useApiKey({ enabled: !!params.apiKeyUuid, params, suspense: false })
  const parent = {
    icon: faWater,
    name: poolQuery.data?.name || "",
    to: `/organizations/${params.organizationUuid}/pools/${params.poolUuid}`,
  }
  if (poolQuery.isInitialLoading || apiKeyQuery.isInitialLoading) return <Skeleton />
  return (
    <Title parent={parent} title={apiKeyQuery.data?.name} uuid={params.apiKeyUuid} {...attrs} />
  )
}

const UserTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const userQuery = useUser({ enabled: !!params.userUuid, params, suspense: false })
  if (userQuery.isInitialLoading) return <Skeleton />
  return <Title title={userQuery.data?.name} uuid={params.userUuid} {...attrs} />
}
