import { createPopper, Options } from "@popperjs/core"
import { RefCallback, useRef, useCallback, useMemo } from "react"

export function usePopper(
  options?: Partial<Options>
): [RefCallback<Element | null>, RefCallback<HTMLElement | null>] {
  const ref = useRef<Element | null>(null)
  const popperRef = useRef<HTMLElement | null>(null)
  const cleanupCallback = useRef<(() => void) | null>(null)

  const instantiatePopper = useCallback(() => {
    if (ref.current && popperRef.current) {
      cleanupCallback.current?.()
      cleanupCallback.current = createPopper(ref.current, popperRef.current, options).destroy
    }
  }, [ref, popperRef, cleanupCallback, options])

  return useMemo(
    () => [
      refDomNode => {
        ref.current = refDomNode
        instantiatePopper()
      },
      popperDomNode => {
        popperRef.current = popperDomNode
        instantiatePopper()
      },
    ],
    [ref, popperRef, instantiatePopper]
  )
}
