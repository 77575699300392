import { faTelescope } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Combobox, Transition } from "@headlessui/react"
import { useNavigate } from "@tanstack/react-location-lite-experimental"
import clsx from "clsx"
import { HTMLAttributes, useMemo, useState } from "react"
import Searchable from "src/components/base/searchables/Searchable"
import Tag from "src/components/base/tags/Tag"
import { usePopper } from "src/components/utils/popper"
import { testIgnoreDiacritics, useSearchRegExps } from "src/hooks/search"
import { useOrganizations } from "src/queries/Organizations"
import { useUsers } from "src/queries/Users"

type TRow = {
  name?: string
  uuid: string
  type: "organization" | "user"
}

const maxResultsSize = 5

type Props = HTMLAttributes<HTMLDivElement> & {
  dropdownClassName?: string
}

const Search = ({ className, dropdownClassName }: Props) => {
  const [trigger, container] = usePopper({
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "bottom-start",
  })
  const navigate = useNavigate()
  const { data: organizations = [] } = useOrganizations({ select: d => d.results, suspense: false })
  const { data: users = [] } = useUsers({ select: d => d.results, suspense: false })
  const [searchValue, setSearchValue] = useState("")
  const searchRegExps = useSearchRegExps(searchValue)

  const handleChange = (value: TRow) => {
    if (value.type === "organization") navigate({ to: `/organizations/${value.uuid}` })
    if (value.type === "user") navigate({ to: `/users/${value.uuid}` })
  }

  const orgRows = useMemo(
    () => organizations.map(o => ({ name: o.name, type: "organization", uuid: o.uuid } as TRow)),
    [organizations]
  )

  const userRows = useMemo(
    () => users.map(o => ({ name: o.name, type: "user", uuid: o.uuid } as TRow)),
    [users]
  )

  const filteredOrgRows = useMemo(() => {
    if (searchRegExps.length === 0) return []
    return orgRows.filter(org => {
      return searchRegExps.every(
        regExp => testIgnoreDiacritics(regExp, org.name) || testIgnoreDiacritics(regExp, org.uuid)
      )
    })
  }, [searchRegExps, orgRows])

  const filteredUserRows = useMemo(() => {
    if (searchRegExps.length === 0) return []
    return userRows.filter(user => {
      return searchRegExps.every(
        regExp => testIgnoreDiacritics(regExp, user.name) || testIgnoreDiacritics(regExp, user.uuid)
      )
    })
  }, [searchRegExps, userRows])

  const orgRowsSlice = filteredOrgRows.slice(0, maxResultsSize)
  const userRowsSlice = filteredUserRows.slice(0, maxResultsSize - orgRowsSlice.length)
  const total = filteredOrgRows.length + filteredUserRows.length

  return (
    <Combobox as="div" className={className} onChange={handleChange}>
      <label className="sr-only" htmlFor="search-field">
        Search
      </label>
      <div className="relative">
        <div
          className={clsx(
            "relative transition-colors",
            "text-slate-400 focus-within:text-slate-500 hover:text-slate-500",
            "dark:text-slate-400 dark:focus-within:text-slate-300 dark:hover:text-slate-300"
          )}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
            <FontAwesomeIcon aria-hidden="true" className="h-5 w-5" icon={faTelescope} />
          </div>
          <Combobox.Input
            ref={trigger}
            autoComplete="off"
            className={clsx(
              "form-input block h-full w-full border-transparent bg-transparent py-2 pl-8 pr-0 sm:text-sm",
              "focus:border-transparent focus:outline-none focus:ring-0",
              "text-slate-900 placeholder-slate-500 focus:placeholder-slate-400",
              "dark:!text-white dark:placeholder-slate-400 dark:focus:placeholder-slate-500"
            )}
            id="search-field"
            placeholder="Lookup resource"
            type="search"
            onChange={event => setSearchValue(event.target.value)}
          />
        </div>

        <div ref={container} className="z-10">
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {searchRegExps.length > 0 && (
              <Combobox.Options
                className={clsx(
                  dropdownClassName,
                  "mt-2 rounded-md py-1 shadow-lg",
                  "bg-white ring-1 ring-slate-200",
                  "dark:bg-slate-800 dark:shadow-slate-500 dark:ring-slate-500"
                )}
              >
                {filteredOrgRows.length > 0 && (
                  <>
                    <Group name="Organizations" rows={filteredOrgRows} />
                    {orgRowsSlice.map(row => (
                      <Option key={row.uuid} row={row} searchRegExps={searchRegExps} />
                    ))}
                  </>
                )}
                {filteredUserRows.length > 0 && (
                  <>
                    <Group name="Users" rows={filteredUserRows} />
                    {userRowsSlice.map(row => (
                      <Option key={row.uuid} row={row} searchRegExps={searchRegExps} />
                    ))}
                  </>
                )}
                {total === 0 && (
                  <div
                    className={clsx(
                      "px-4 py-2 text-center text-sm italic",
                      "text-slate-700",
                      "dark:text-slate-300"
                    )}
                  >
                    No results
                  </div>
                )}
              </Combobox.Options>
            )}
          </Transition>
        </div>
      </div>
    </Combobox>
  )
}

type GroupProps = { name: string; rows: TRow[] }

const Group = ({ name, rows }: GroupProps) => (
  <div
    className={clsx(
      "flex items-center justify-between px-4 pt-2 pb-1 text-xs font-medium uppercase first-of-type:pt-1",
      "text-slate-400" // same in dark
    )}
  >
    {name}
    <Tag color="grey" variant="light">
      {rows.length}
    </Tag>
  </div>
)

type OptionProps = { row: TRow; searchRegExps: RegExp[] }

const Option = ({ row, searchRegExps }: OptionProps) => (
  <Combobox.Option
    key={row.uuid}
    className={({ active }) =>
      clsx(
        "block cursor-pointer px-4 py-2 text-left text-sm",
        active ? "bg-slate-100 text-slate-900" : "text-slate-700",
        active ? "dark:bg-slate-700 dark:text-white" : "dark:text-slate-200"
      )
    }
    value={row}
  >
    {({ active }) => (
      <>
        <Searchable className="block truncate" searchRegExps={searchRegExps} value={row.name} />
        <Searchable
          className={clsx(
            "truncat block",
            active ? "text-slate-600" : "text-slate-400",
            active ? "dark:text-slate-400" : "dark:text-slate-400"
          )}
          searchRegExps={searchRegExps}
          value={row.uuid}
        />
      </>
    )}
  </Combobox.Option>
)

export default Search
