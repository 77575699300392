import clsx from "clsx"
import { cloneElement } from "react"

type Props = {
  children: [JSX.Element, JSX.Element]
}

const ModalConfirmButtons = ({ children: [okButton, cancelButton] }: Props) => (
  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
    {cloneElement(okButton, {
      className: clsx(okButton.props.className, "w-full text-base sm:w-auto sm:text-sm sm:ml-3"),
    })}

    {cloneElement(cancelButton, {
      className: clsx(
        cancelButton.props.className,
        "w-full text-base mt-3 sm:w-auto sm:text-sm sm:mt-0"
      ),
    })}
  </div>
)

export default ModalConfirmButtons
