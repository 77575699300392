import { UseQueryOptions, useQuery, useMutation, UseMutationOptions } from "@tanstack/react-query"
import { useParams } from "src/helpers/location"
import { useApi } from "src/hooks/api"
import { queryClient } from "./client"

export const useUsers = <TData = api.user.PaginatedUserList,>(
  options?: UseQueryOptions<api.user.PaginatedUserList, unknown, TData>
) => {
  const { jsonGet } = useApi("user")
  return useQuery(
    ["users"],
    ({ signal }) => jsonGet(`/users/?page_size=100000`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUser = <TData = api.user.DetailedUser,>(
  options?: UseQueryOptions<api.user.DetailedUser, unknown, TData> & {
    params?: { userUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { userUuid } = useParams(options?.params)
  return useQuery(
    ["users", userUuid],
    ({ signal }) => jsonGet(`/users/${userUuid}/`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUpdateUser = (
  options?: UseMutationOptions<
    api.user.DetailedUser,
    unknown,
    DeepPartial<api.user.DetailedUser>
  > & {
    params?: { userUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { userUuid } = useParams(options?.params)
  return useMutation(data => jsonPatch(`/users/${userUuid}/`, data), {
    onSuccess: async data => {
      queryClient.setQueryData(["users", userUuid], data)
      queryClient.removeQueries(["users"], { exact: true })
    },
    ...options,
  })
}

export const useDeleteUser = (
  options?: UseMutationOptions & { params?: { userUuid?: string } }
) => {
  const { jsonDelete } = useApi("user")
  const { userUuid } = useParams(options?.params)
  return useMutation(() => jsonDelete(`/users/${userUuid}/`), {
    onSuccess: async () => {
      await queryClient.refetchQueries(["users"], { exact: true })
      setTimeout(() => queryClient.removeQueries(["users", userUuid]), 1000)
    },
    ...options,
  })
}

export const useResetPassword = (
  options?: UseMutationOptions<unknown, unknown, api.user.ResetPassword>
) => {
  const { jsonPost } = useApi("user")
  return useMutation(data => jsonPost(`/manage/user/reset_password/`, data), options)
}
