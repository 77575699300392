import { useLocation, useNavigate } from "@tanstack/react-location-lite-experimental"
import { useCallback, useState } from "react"

export const useCloseModal = () => {
  const navigate = useNavigate()
  const [hasHistory] = useState(window.history.length > 2)
  const location = useLocation()

  const closeModal = useCallback(() => {
    if (hasHistory) {
      location.history.back()
    } else {
      navigate({
        replace: true,
        search: old => ({ ...old, modal: undefined }),
      })
    }
  }, [location.history, hasHistory, navigate])

  return closeModal
}
