import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { AppError } from "src/helpers/errors"
import { ApiError } from "src/services/api"
import styles from "./Loader.module.css"

type Props = {
  error?: any // React Query error or any other error object
  isCover?: boolean
  isFullPage?: boolean
  isModal?: boolean
}

const Loader = ({ error, isCover, isFullPage, isModal }: Props) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsActive(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      className={
        isCover
          ? styles.isCover
          : isFullPage
          ? styles.isFullPage
          : isModal
          ? styles.isModal
          : undefined
      }
    >
      {isActive && (
        <div>
          {error ? (
            <div className={styles.errorIcon}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          ) : (
            <div className={styles.loader} />
          )}
          <div className={styles.title}>
            {error ? (
              <span className={error ? "text-red-600" : styles.fade}>
                {error
                  ? error.message === "Failed to fetch"
                    ? "We cannot reach the API… Make sure your network connection is active."
                    : error instanceof ApiError
                    ? `Oops. The API returned an HTTP ${error.status}…`
                    : error instanceof AppError
                    ? error.message
                    : "Oops, an error has occurred"
                  : null}
              </span>
            ) : (
              "Loading"
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Loader
