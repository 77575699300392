import { Link, useLocation } from "@tanstack/react-location-lite-experimental"
import { ReactNode, useState } from "react"
import img404_avif from "src/images/404.avif"
import img404_jpg from "src/images/404.jpg"
import img404_webp from "src/images/404.webp"

type Props = {
  description?: ReactNode
  title?: string
  subtitle?: string
}

const ctaClassName =
  "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"

const NotFound = ({
  subtitle = "404 error",
  description = "This page does not exist or has been moved to another location.",
  title = "Uh oh! I think you’re lost.",
}: Props) => {
  const [hasHistory] = useState(window.history.length > 2)
  const location = useLocation()
  return (
    <>
      <main className="relative min-h-full">
        <picture>
          <source srcSet={img404_avif} type="image/avif" />
          <source srcSet={img404_webp} type="image/webp" />
          <img alt="" className="absolute h-full w-full object-cover" src={img404_jpg} />
        </picture>
        <div className="relative mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold uppercase tracking-wide text-black text-opacity-60">
            {subtitle}
          </p>
          <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-black text-opacity-75 sm:text-5xl">
            {title}
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-60">{description}</p>
          <div className="mt-6">
            {hasHistory ? (
              <button
                className={ctaClassName}
                type="button"
                onClick={() => location.history.back()}
              >
                Go back
              </button>
            ) : (
              <Link className={ctaClassName} to="/">
                Go back home
              </Link>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default NotFound
