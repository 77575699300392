import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { captureException, setUser } from "@sentry/browser"
import { ReactNode, useEffect } from "react"
import Loader from "../base/misc/Loader"

type Props = { children: ReactNode }

const AuthenticationRequired = ({ children }: Props) => {
  const { error, isLoading, user } = useAuth0()

  useEffect(() => {
    if (user) setUser({ email: user.email })
  }, [user])

  useEffect(() => {
    if (error) captureException(error)
  }, [error])

  if (isLoading) <Loader isCover />

  return <>{children}</>
}

// eslint-disable-next-line react/display-name
export default withAuthenticationRequired(AuthenticationRequired, {
  onRedirecting: () => <Loader isCover />,
})
