import { Menu, Popover } from "@headlessui/react"
import { LinkProps } from "@tanstack/react-location-lite-experimental"
import clsx from "clsx"
import {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardedRef,
  ReactNode,
  AnchorHTMLAttributes,
} from "react"
import LinkWithForwardRef from "src/components/utils/LinkWithForwardRef"

type BaseProps = {
  children: ReactNode
  color:
    | "danger"
    | "danger-outline"
    | "ghost"
    | "primary"
    | "primary-outline"
    | "secondary"
    | "success"
    | "warning"
    | "warning-outline"
    | "white"
  size?: "normal" | "small"
  isLoading?: boolean
}
export type ButtonAsAnchor = BaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    as: "anchor"
    disabled?: boolean
  }
export type ButtonAsButton = BaseProps & ButtonHTMLAttributes<HTMLButtonElement> & { as?: "button" }
export type ButtonAsLink = BaseProps & LinkProps & { as: "link"; disabled?: boolean }
export type ButtonAsMenuButton = BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as: "menuButton"
    disabled?: boolean
  }
export type ButtonAsPopoverButton = BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as: "popoverButton"
    disabled?: boolean
  }
export type Props =
  | ButtonAsAnchor
  | ButtonAsButton
  | ButtonAsLink
  | ButtonAsMenuButton
  | ButtonAsPopoverButton

const colorToCls = {
  danger: "border-transparent text-white bg-red-600 after:border-white shadow-sm",
  "danger-outline": "border-red-300 text-red-700 bg-white after:border-red-700 shadow-sm",
  ghost: "border-transparent text-slate-600 after:border-slate-600",
  primary: "border-transparent text-white bg-blue-600 after:border-white shadow-sm",
  "primary-outline": "border-blue-300 text-blue-700 bg-white after:border-blue-700 shadow-sm",
  secondary: "border-transparent text-slate-700 bg-slate-100 after:border-slate-700 shadow-sm",
  success: "border-transparent text-white bg-green-600 after:border-white shadow-sm",
  warning: "border-transparent text-white bg-orange-600 after:border-white shadow-sm",
  "warning-outline": "border-orange-300 text-orange-700 bg-white after:border-orange-700 shadow-sm",
  white: "border-slate-300 text-slate-700 bg-white after:border-slate-700 shadow-sm",
}

const colorToHoverCls = {
  danger: "hover:bg-red-700",
  "danger-outline": "hover:bg-red-50",
  ghost: "hover:text-slate-700 hover:bg-slate-100",
  primary: "hover:bg-blue-700",
  "primary-outline": "hover:bg-blue-50",
  secondary: "hover:bg-slate-200",
  success: "hover:bg-green-700",
  warning: "hover:bg-orange-700",
  "warning-outline": "hover:bg-orange-50",
  white: "hover:bg-slate-50",
}

const sizeToCls = {
  normal: "px-4 py-2 text-sm",
  small: "px-2 py-1 text-xs",
}

const Button = (props: Props, ref: ForwardedRef<null>) => {
  const disabled = props.disabled || props.isLoading
  const cls = clsx(
    "inline-flex items-center justify-center border font-medium rounded-md select-none",
    sizeToCls[props.size || "normal"],
    colorToCls[props.color],
    disabled ? "cursor-not-allowed opacity-50" : colorToHoverCls[props.color],
    props.isLoading &&
      "relative text-transparent after:absolute after:border-2 after:!border-t-transparent after:!border-l-transparent after:rounded-full after:block after:h-[1.25em] after:w-[1.25em] after:animate-[spin_500ms_linear_infinite]",
    props.className
  )

  if (props.as === "anchor") {
    const { as, className, children, color, isLoading, size, ...attrs } = props
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <a
        ref={ref}
        className={cls}
        onClick={disabled ? e => e.preventDefault() : undefined}
        {...attrs}
      >
        {children}
      </a>
    )
  }

  if (props.as === "link") {
    const { as, className, children, color, isLoading, size, ...attrs } = props
    return (
      <LinkWithForwardRef
        className={cls}
        {...attrs}
        onClick={e => {
          if (disabled) e.preventDefault() // work-around for issue https://github.com/TanStack/react-location/issues/230
          attrs.onClick?.(e)
        }}
      >
        {children}
      </LinkWithForwardRef>
    )
  }

  if (props.as === "popoverButton") {
    const { as, className, children, color, isLoading, size, ...attrs } = props
    return (
      <Popover.Button ref={ref} className={cls} disabled={disabled} {...attrs}>
        {children}
      </Popover.Button>
    )
  }

  if (props.as === "menuButton") {
    const { as, className, children, color, isLoading, size, ...attrs } = props
    return (
      <Menu.Button ref={ref} className={cls} disabled={disabled} {...attrs}>
        {children}
      </Menu.Button>
    )
  }

  const { as, className, children, color, isLoading, size, ...attrs } = props
  return (
    <button ref={ref} className={cls} disabled={disabled} {...attrs}>
      {children}
    </button>
  )
}

export default forwardRef(Button)
