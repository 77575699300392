import { useLocation } from "@tanstack/react-location-lite-experimental"
import { useEffect, useState } from "react"
import NotFound from "src/App/Landings/NotFound"
import Loader from "src/components/base/misc/Loader"
import CaptureError from "src/components/utils/CaptureError"
import { NotFoundError } from "src/helpers/errors"
import { ApiError } from "src/services/api"

type Props = {
  error: any
  resetErrorBoundary: () => void
}

const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const location = useLocation()
  const { href } = location.current
  const [initialHref] = useState(href)

  useEffect(() => {
    if (href !== initialHref && resetErrorBoundary) {
      resetErrorBoundary()
    }
  }, [location, href]) // eslint-disable-line react-hooks/exhaustive-deps

  // Page not found
  if (error instanceof NotFoundError) {
    return <NotFound />
  }

  // API 404
  if (error instanceof ApiError && error.status === 404) {
    return <NotFound description="Some of the content required on this page could not be found." />
  }

  // Generic error page
  return (
    <>
      <CaptureError error={error} />
      <Loader error={error} isCover />
    </>
  )
}

export default ErrorFallback
