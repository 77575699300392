import { init } from "@sentry/browser"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "src/App"

const sentryDsn = import.meta.env.REACT_APP_SENTRY_DSN
if (typeof sentryDsn === "string") {
  init({
    dsn: sentryDsn,
    environment: import.meta.env.REACT_APP_SENTRY_ENV || "default",
    release: import.meta.env.REACT_APP_SENTRY_RELEASE,
  })
}

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}
