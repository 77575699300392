import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { HTMLAttributes } from "react"

export type Props = HTMLAttributes<HTMLSpanElement> & {
  align?: "baseline" | "middle" | "top"
  removeBtnProps?: HTMLAttributes<HTMLDivElement>
  color: "grey" | "pink" | "purple" | "red" | "teal"
  variant?: "light" | "default"
}

const variantToColorToCls = {
  default: {
    grey: "text-white bg-slate-600",
    pink: "text-white bg-pink-600",
    purple: "text-white bg-purple-600",
    red: "text-white bg-red-600",
    teal: "text-white bg-teal-600",
  },
  light: {
    grey: "text-slate-800 bg-slate-200",
    pink: "text-pink-800 bg-pink-200",
    purple: "text-purple-800 bg-purple-200",
    red: "text-red-800 bg-red-200",
    teal: "text-teal-800 bg-teal-200",
  },
}

const variantToRemoveBtnColorToCls = {
  default: {
    grey: "text-slate-400 hover:text-white hover:bg-slate-500",
    pink: "text-pink-400 hover:text-white hover:bg-pink-500",
    purple: "text-purple-400 hover:text-white hover:bg-purple-500",
    red: "text-red-400 hover:text-white hover:bg-red-500",
    teal: "text-teal-400 hover:text-white hover:bg-teal-500",
  },
  light: {
    grey: "text-slate-400 bg-slate-200 hover:text-slate-900 hover:bg-slate-300",
    pink: "text-pink-400 bg-pink-200 hover:text-pink-900 hover:bg-pink-300",
    purple: "text-purple-400 bg-purple-200 hover:text-purple-900 hover:bg-purple-300",
    red: "text-red-400 bg-red-200 hover:text-red-900 hover:bg-red-300",
    teal: "text-teal-400 bg-teal-200 hover:text-teal-900 hover:bg-teal-300",
  },
}

const alignToCls = {
  baseline: "align-baseline",
  middle: "align-middle",
  top: "align-top",
}

const Tag = ({
  align = "middle",
  children,
  className,
  color,
  removeBtnProps,
  variant = "default",
}: Props) => {
  return (
    <span
      className={clsx(
        "inline-flex whitespace-nowrap rounded align-top text-xs leading-normal",
        alignToCls[align],
        variantToColorToCls[variant][color],
        className
      )}
    >
      <span className={clsx("py-0.5", removeBtnProps ? "pl-2 pr-1" : "px-2")}>{children}</span>
      {removeBtnProps && (
        <div
          className={clsx(
            "flex items-center rounded-t-none rounded-r rounded-b rounded-l-none px-1.5 text-xs",
            variantToRemoveBtnColorToCls[variant][color]
          )}
          role="button"
          {...removeBtnProps}
        >
          <FontAwesomeIcon aria-hidden="true" icon={faTimes} />
        </div>
      )}
    </span>
  )
}

export default Tag
