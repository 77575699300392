import { HTMLAttributes } from "react"

type Props = HTMLAttributes<HTMLDivElement>

const Skeleton = ({ ...attrs }: Props) => (
  <div className="animate-fade-in-skeleton opacity-0" {...attrs}>
    <div className="flex animate-pulse items-center space-x-4">
      <div className="h-4 w-32 rounded bg-slate-200"></div>
      <div className="h-3 w-32 rounded bg-slate-200"></div>
    </div>
  </div>
)

export default Skeleton
// transition-opacity delay-1000 duration-500
