import { captureException } from "@sentry/browser"
import { useEffect } from "react"

type Props = { error: any }

const CaptureError = ({ error }: Props) => {
  useEffect(() => {
    captureException(error)
  }, [error])

  return null
}

export default CaptureError
