import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { useParams } from "src/helpers/location"
import { useApi } from "src/hooks/api"
import { queryClient } from "./client"

export const usePools = <TData = api.ott.PaginatedPoolList>(
  options?: UseQueryOptions<api.ott.PaginatedPoolList, unknown, TData> & {
    params?: { organizationUuid?: string }
  }
) => {
  const { jsonGet } = useApi("ott")
  const { organizationUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "pools"],
    ({ signal }) => jsonGet(`/pools/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const usePool = <TData = api.ott.PrivatePool>(
  options?: UseQueryOptions<api.ott.PrivatePool, unknown, TData> & {
    params?: { organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonGet } = useApi("ott")
  const { organizationUuid, poolUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "pools", poolUuid],
    ({ signal }) => jsonGet(`/manage/ott/pools/${poolUuid}/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUpdatePool = (
  options?: UseMutationOptions<api.ott.PrivatePool, unknown, DeepPartial<api.ott.PrivatePool>> & {
    params?: { organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("ott")
  const { organizationUuid, poolUuid } = useParams(options?.params)
  return useMutation(
    data => jsonPatch(`/manage/ott/pools/${poolUuid}/?org=${organizationUuid}`, data),
    {
      onSuccess: async () => {
        queryClient.removeQueries(["orgs", organizationUuid, "pools"], { exact: true })
        await queryClient.refetchQueries(["orgs", organizationUuid, "pools", poolUuid])
      },
      ...options,
    }
  )
}

export const useDeletePool = (
  options?: UseMutationOptions & {
    params?: { organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonDelete } = useApi("ott")
  const { organizationUuid, poolUuid } = useParams(options?.params)
  return useMutation(
    () => jsonDelete(`/pools/${poolUuid}/?org=${organizationUuid}&pool_uuid=${poolUuid}`),
    {
      onSuccess: async () => {
        queryClient.removeQueries(["orgs", organizationUuid, "pools"], { exact: true })
        setTimeout(
          () => queryClient.removeQueries(["orgs", organizationUuid, "pools", poolUuid]),
          1000
        )
      },
      ...options,
    }
  )
}

//------------------------------------------------------------------------------
// Pool API keys
//------------------------------------------------------------------------------

export const usePoolApiKeys = <TData = api.ott.PaginatedPoolApiKeyList>(
  options?: UseQueryOptions<api.ott.PaginatedPoolApiKeyList, unknown, TData> & {
    params?: { organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonGet } = useApi("ott")
  const { organizationUuid, poolUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "pools", poolUuid, "apiKeys"],
    ({ signal }) => jsonGet(`/pools/${poolUuid}/api_keys/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const usePoolApiKey = <TData = api.ott.PoolApiKey>(
  options?: UseQueryOptions<api.ott.PoolApiKey, unknown, TData> & {
    params?: { apiKeyUuid?: string; organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonGet } = useApi("ott")
  const { organizationUuid, poolUuid, apiKeyUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "pools", poolUuid, "apiKeys", apiKeyUuid],
    ({ signal }) =>
      jsonGet(`/pools/${poolUuid}/api_keys/${apiKeyUuid}/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUpdatePoolApiKey = (
  options?: UseMutationOptions<api.ott.PoolApiKey, unknown, DeepPartial<api.ott.PoolApiKey>> & {
    params?: { apiKeyUuid?: string; organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("ott")
  const { organizationUuid, poolUuid, apiKeyUuid } = useParams(options?.params)
  return useMutation(
    data => jsonPatch(`/pools/${poolUuid}/api_keys/${apiKeyUuid}/?org=${organizationUuid}`, data),
    {
      onSuccess: async data => {
        queryClient.setQueryData(
          ["orgs", organizationUuid, "pools", poolUuid, "apiKeys", apiKeyUuid],
          data
        )
        queryClient.removeQueries(["orgs", organizationUuid, "pools", poolUuid, "apiKeys"], {
          exact: true,
        })
        queryClient.removeQueries(["orgs", organizationUuid, "apiKeys"], { exact: true })
        await queryClient.refetchQueries(["orgs", organizationUuid, "apiKeys", apiKeyUuid], {
          exact: true,
        })
      },
      ...options,
    }
  )
}

export const useDeletePoolApiKey = (
  options?: UseMutationOptions & {
    params?: { apiKeyUuid?: string; organizationUuid?: string; poolUuid?: string }
  }
) => {
  const { jsonDelete } = useApi("ott")
  const { organizationUuid, poolUuid, apiKeyUuid } = useParams(options?.params)
  return useMutation(
    () => jsonDelete(`/pools/${poolUuid}/api_keys/${apiKeyUuid}/?org=${organizationUuid}`),
    {
      onSuccess: async () => {
        queryClient.removeQueries(["orgs", organizationUuid, "pools", poolUuid, "apiKeys"], {
          exact: true,
        })
        queryClient.removeQueries(["orgs", organizationUuid, "apiKeys"], { exact: true })
        setTimeout(
          () =>
            queryClient.removeQueries([
              "orgs",
              organizationUuid,
              "pools",
              poolUuid,
              "apiKeys",
              apiKeyUuid,
            ]),
          1000
        )
      },
      ...options,
    }
  )
}
