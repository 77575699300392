import { IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-location-lite-experimental"
import clsx from "clsx"
import { HTMLAttributes } from "react"

type Props = HTMLAttributes<HTMLDivElement> & {
  title?: string
  uuid?: string
  parent?: {
    icon: IconDefinition
    name: string
    to: string
  }
}

const Title = ({ className, parent, title, uuid, ...attrs }: Props) => (
  <div
    className={clsx("flex min-w-0 flex-col gap-x-5 lg:flex-row lg:items-center", className)}
    {...attrs}
  >
    {parent && (
      <Link
        className={clsx(
          "select-none overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-600",
          "transition-colors hover:text-slate-900",
          "lg:text-sm"
        )}
        to={parent.to}
      >
        <FontAwesomeIcon aria-hidden="true" className="mr-2" icon={parent.icon} />
        {parent.name}
      </Link>
    )}
    {parent && <div className="hidden h-5 border-l border-slate-400 lg:block" />}

    <div className="flex min-w-0 items-center gap-x-3">
      <h1 className="overflow-hidden text-ellipsis whitespace-nowrap text-xl font-medium lg:text-2xl">
        {title || "<empty name>"}
      </h1>
      {uuid && (
        <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-slate-400 lg:text-sm">
          {uuid}
        </div>
      )}
    </div>
  </div>
)

export default Title
