import "src/styles/index.css"
import "src/styles/toastify.scss"
import { Auth0Provider } from "@auth0/auth0-react"
import { captureException } from "@sentry/browser"
import { Outlet, ReactLocation, Router } from "@tanstack/react-location-lite-experimental"
import { QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { ToastContainer } from "react-toastify"
import Loader from "src/components/base/misc/Loader"
import AuthenticationRequired from "src/components/utils/AuthenticationRequired"
import NoTrailingSlash from "src/components/utils/NoTrailingSlash"
import { queryClient } from "src/queries/client"
import ErrorFallback from "./ErrorFallback"
import { routes } from "./routes"

const reactLocation = new ReactLocation()

const App = () => (
  <ErrorBoundary
    fallbackRender={({ error }) => <Loader error={error} isCover />}
    onError={error => captureException(error)}
  >
    <Suspense fallback={<Loader isCover />}>
      <Auth0Provider
        authorizationParams={{
          audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        }}
        cacheLocation="localstorage"
        clientId={import.meta.env.REACT_APP_AUTH0_CLIENT_ID}
        domain={import.meta.env.REACT_APP_AUTH0_DOMAIN}
        issuer={import.meta.env.REACT_APP_AUTH0_ISSUER}
        useFormData
        useRefreshTokens
        useRefreshTokensFallback
        onRedirectCallback={appState => {
          const returnTo = appState?.returnTo
          if (returnTo) reactLocation.history.push(returnTo)
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AuthenticationRequired>
            <Router
              defaultPendingElement={<Loader isFullPage />}
              location={reactLocation}
              routes={routes}
            >
              <NoTrailingSlash />
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    fallbackRender={({ error, resetErrorBoundary }) => (
                      <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
                    )}
                    onReset={reset}
                  >
                    <Outlet />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
              <ToastContainer
                closeButton={({ closeToast }) => <button className="delete" onClick={closeToast} />}
                closeOnClick={false}
                hideProgressBar
                icon={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="colored"
              />
            </Router>
          </AuthenticationRequired>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </Auth0Provider>
    </Suspense>
  </ErrorBoundary>
)

export default App
