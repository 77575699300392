import { Dialog } from "@headlessui/react"
import { ReactNode } from "react"

type Props = { children: ReactNode }

const ModalTitle = ({ children }: Props) => (
  <Dialog.Title as="h3" className="text-lg font-medium leading-6">
    {children}
  </Dialog.Title>
)

export default ModalTitle
