import { QueryClient } from "@tanstack/react-query"
import { ApiError } from "src/services/api"

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      useErrorBoundary: false,
    },
    queries: {
      retry: (failureCount, error) =>
        error instanceof ApiError && error.status >= 400 ? false : 3 - failureCount > 0,
      staleTime: 1000,
      suspense: true,
    },
  },
})
