import {
  faBars,
  faEnvelope,
  faSackDollar,
  faBuildings,
  faUserGroup,
  faUserGroupCrown,
  faXmark,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, Menu, Transition } from "@headlessui/react"
import {
  Link,
  LinkProps,
  Outlet,
  useLocation,
  useSearch,
} from "@tanstack/react-location-lite-experimental"
import clsx from "clsx"
import { Fragment, Suspense, useEffect, useMemo, useState } from "react"
import TextContainer from "src/components/base/containers/TextContainer"
import Loader from "src/components/base/misc/Loader"
import PageTitle from "src/components/wired/misc/PageTitle"
import quortexLogo from "src/images/quortex-logo.svg"
import { useAdminMe } from "src/queries/AdminUsers"
import LogoutModal, { LogoutModalGenerics } from "../../components/wired/modals/LogoutModal"
import Search from "./Search"

const Main = () => {
  const { data: me } = useAdminMe()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const { pathname } = location.current
  const search = useSearch()

  const navigation: { icon: IconDefinition; link: LinkProps; name: string }[] = useMemo(
    () => [
      { icon: faBuildings, link: { to: "/organizations" }, name: "Organizations" },
      { icon: faUserGroup, link: { to: "/users" }, name: "Users" },
      {
        icon: faSackDollar,
        link: { to: "/billing" },
        name: "Billing accounts",
      },
      {
        icon: faEnvelope,
        link: { search: { search: "status:waiting" }, to: "/waiting-list" },
        name: "Waiting list",
      },

      { icon: faUserGroupCrown, link: { to: "/admin-users" }, name: "Admin users" },
    ],
    []
  )

  // https://github.com/TanStack/react-location/issues/264
  const userNavigation: { link: LinkProps<LogoutModalGenerics>; name: string }[] = useMemo(
    () => [
      { link: { search: { ...search, modal: "confirm_logout" }, to: pathname }, name: "Sign out" },
    ],
    [pathname, search]
  )

  useEffect(() => {
    setSidebarOpen(wasOpen => (wasOpen ? false : wasOpen))
  }, [pathname])

  return (
    <>
      <Transition.Root as={Fragment} show={sidebarOpen}>
        <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-slate-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-slate-800 pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <FontAwesomeIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                      icon={faXmark}
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center whitespace-nowrap px-4 text-xl font-bold tracking-wide text-white">
                <img alt="" className="mr-3 h-8 w-auto" src={quortexLogo} />
                Back-office
              </div>
              <div className="mt-4 h-0 flex-1 overflow-y-auto pt-1">
                <Search className="dark ml-5 mr-4 mb-3" dropdownClassName="w-[17.5rem]" />
                <nav className="space-y-1 px-2 py-0">
                  {navigation.map(item => (
                    <Link
                      key={item.name}
                      className={clsx(
                        pathname.startsWith(`${item.link.to}`)
                          ? "bg-slate-900 text-white"
                          : clsx(
                              "text-slate-300",
                              !item.link.disabled && "hover:bg-slate-700 hover:text-white"
                            ),
                        "group flex items-center rounded-md px-2 py-2 text-base font-medium",
                        item.link.disabled && "opacity-50"
                      )}
                      {...item.link}
                    >
                      <FontAwesomeIcon
                        aria-hidden="true"
                        className={clsx(
                          pathname.startsWith(`${item.link.to}`)
                            ? "text-slate-300"
                            : clsx(
                                "text-slate-400",
                                !item.link.disabled && "group-hover:text-slate-300"
                              ),
                          "mr-4 h-6 w-6 flex-shrink-0"
                        )}
                        icon={item.icon}
                      />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div aria-hidden="true" className="w-14 flex-shrink-0">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-56 md:flex-col lg:w-64">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-slate-800">
          <div className="flex h-16 flex-shrink-0 items-center whitespace-nowrap bg-slate-900 px-4 text-xl font-bold tracking-wide text-white">
            <img alt="" className="mr-3 h-8 w-auto" src={quortexLogo} />
            Back-office
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="flex-1 space-y-1 px-2 py-4">
              {navigation.map(item => (
                <Link
                  key={item.name}
                  className={clsx(
                    pathname.startsWith(`${item.link.to}`)
                      ? "bg-slate-900 text-white"
                      : clsx(
                          "text-slate-300",
                          !item.link.disabled && "hover:bg-slate-700 hover:text-white"
                        ),
                    "group flex items-center rounded-md px-2 py-2 text-sm font-medium",
                    item.link.disabled && "opacity-50"
                  )}
                  {...item.link}
                >
                  <FontAwesomeIcon
                    aria-hidden="true"
                    className={clsx(
                      pathname.startsWith(`${item.link.to}`)
                        ? "text-slate-300"
                        : clsx(
                            "text-slate-400",
                            !item.link.disabled && "group-hover:text-slate-300"
                          ),
                      "mr-3 h-6 w-6 flex-shrink-0"
                    )}
                    icon={item.icon}
                  />
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex h-full flex-col md:pl-56 lg:pl-64">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            className="border-r border-slate-200 px-4 text-slate-500 md:hidden"
            type="button"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <FontAwesomeIcon aria-hidden="true" className="h-6 w-6" icon={faBars} />
          </button>
          <TextContainer className="mx-auto flex min-w-0 max-w-7xl flex-1 justify-between">
            <div className="flex min-w-0 flex-1 items-center justify-between">
              <PageTitle />
              <Search className="ml-4 hidden w-40 md:block" dropdownClassName="w-80" />
            </div>
            <div className="flex items-center">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm  ">
                    <span className="sr-only">Open user menu</span>

                    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-slate-100">
                      <img alt="Me" referrerPolicy="no-referrer" src={me!.profilePictureUrl} />
                      <svg
                        className="h-full w-full text-slate-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                    {userNavigation.map(item => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <span>
                            <Link
                              className={clsx(
                                active && "bg-slate-100",
                                "block px-4 py-2 text-sm text-slate-700"
                              )}
                              {...item.link}
                            >
                              {item.name}
                            </Link>
                          </span>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </TextContainer>
        </div>
        <main className="flex-1">
          <div className="mx-auto mb-32 h-full max-w-7xl">
            <Suspense fallback={<Loader isFullPage />}>
              <Outlet />
            </Suspense>
          </div>
        </main>
      </div>

      <LogoutModal />
    </>
  )
}

export default Main
