/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Navigate, Route } from "@tanstack/react-location-lite-experimental"
import { lazy } from "react"
import { NotFoundError } from "src/helpers/errors"
import Main from "./Main"

// Organizations
const ListOrganizations = lazy(() => import("./Main/organizations/ListOrganizations"))
const ViewOrganization = lazy(() => import("./Main/organizations/ViewOrganization"))

// Billing
const ListBillingAccounts = lazy(() => import("./Main/billing/ListBillingAccounts"))
const ViewBillingInfo = lazy(() => import("./Main/billing/ViewBillingInfo"))

// Pools
const ViewPool = lazy(() => import("./Main/pools/ViewPool"))
const PropertiesTab = lazy(() => import("./Main/pools/ViewPool/propertiesTab"))

// Inputs
const ListInputs = lazy(() => import("./Main/pools/ViewPool/inputsTab/ListInputs"))
const ViewInput = lazy(() => import("./Main/pools/ViewPool/inputsTab/ViewInput"))

// Streams
const ListStreams = lazy(() => import("./Main/pools/ViewPool/streamsTab/ListStreams"))
const ViewStream = lazy(() => import("./Main/pools/ViewPool/streamsTab/ViewStream"))

// Processings
const ListProcessings = lazy(() => import("./Main/pools/ViewPool/processingsTab/ListProcessings"))
const ViewProcessing = lazy(() => import("./Main/pools/ViewPool/processingsTab/ViewProcessing"))

// Targets
const ListTargets = lazy(() => import("./Main/pools/ViewPool/targetsTab/ListTargets"))
const ViewTarget = lazy(() => import("./Main/pools/ViewPool/targetsTab/ViewTarget"))

// Users
const ListUsers = lazy(() => import("./Main/users/ListUsers"))
const ViewUser = lazy(() => import("./Main/users/ViewUser"))

// Waiting list
const ListWaitingUsers = lazy(() => import("./Main/waiting/ListWaitingUsers"))

// API keys
const ViewApiKey = lazy(() => import("./Main/organizations/ViewApiKey"))
const ViewPoolApiKey = lazy(() => import("./Main/pools/ViewPoolApiKey"))

// Admin Users
const ListAdminUsers = lazy(() => import("./Main/users/ListAdminUsers"))
const ViewAdminUser = lazy(() => import("./Main/users/ViewAdminUser"))

const ThrowNotFound = () => {
  throw new NotFoundError()
}

export const routes: Route<DefaultGenerics>[] = [
  {
    path: "/",
    id: "main",
    element: <Main />,
    children: [
      { path: "billing", element: <ListBillingAccounts /> },
      {
        path: "organizations",
        children: [
          { path: "/", element: <ListOrganizations /> },
          {
            path: ":organizationUuid",
            children: [
              { path: "/", element: <ViewOrganization /> },
              { path: "/api-keys/:apiKeyUuid", element: <ViewApiKey /> },
              { path: "/billing", element: <ViewBillingInfo /> },
              { path: "/pools/:poolUuid/api-keys/:apiKeyUuid", element: <ViewPoolApiKey /> },
              {
                path: "/pools/:poolUuid",
                element: <ViewPool />,
                children: [
                  { path: "/", element: <PropertiesTab /> },
                  {
                    path: "/inputs",
                    children: [
                      { path: "/", element: <ListInputs /> },
                      { path: ":inputUuid/", element: <ViewInput /> },
                      { path: ":inputUuid/streams/:streamUuid", element: <ViewStream /> },
                      { element: <ThrowNotFound /> },
                    ],
                  },
                  {
                    path: "/streams",
                    children: [
                      { path: "/", element: <ListStreams /> },
                      { element: <ThrowNotFound /> },
                    ],
                  },
                  {
                    path: "/processing",
                    children: [
                      { path: "/", element: <ListProcessings /> },
                      { path: ":processingUuid", element: <ViewProcessing /> },
                      { element: <ThrowNotFound /> },
                    ],
                  },
                  {
                    path: "/targets",
                    children: [
                      { path: "/", element: <ListTargets /> },
                      { path: ":targetUuid", element: <ViewTarget /> },
                      { element: <ThrowNotFound /> },
                    ],
                  },
                  { element: <ThrowNotFound /> },
                ],
              },
              { element: <ThrowNotFound /> },
            ],
          },
          { element: <ThrowNotFound /> },
        ],
      },
      {
        path: "users",
        children: [
          { path: "/", element: <ListUsers /> },
          { path: ":userUuid", element: <ViewUser /> },
          { element: <ThrowNotFound /> },
        ],
      },
      {
        path: "admin-users",
        children: [
          { path: "/", element: <ListAdminUsers /> },
          { path: ":adminUserUuid", element: <ViewAdminUser /> },
          { element: <ThrowNotFound /> },
        ],
      },
      {
        path: "waiting-list",
        children: [
          { path: "/", element: <ListWaitingUsers /> }, //
          { element: <ThrowNotFound /> },
        ],
      },
      { path: "/", element: <Navigate to="/organizations" /> },
      { element: <ThrowNotFound /> },
    ],
  },
  { element: <ThrowNotFound /> },
]
