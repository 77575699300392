import { UseQueryOptions, useQuery, useMutation, UseMutationOptions } from "@tanstack/react-query"
import { useParams } from "src/helpers/location"
import { useApi } from "src/hooks/api"
import { queryClient } from "./client"

export const useAdminUsers = <TData = api.user.PaginatedAdminUserList,>(
  options?: UseQueryOptions<api.user.PaginatedAdminUserList, unknown, TData>
) => {
  const { jsonGet } = useApi("user")
  return useQuery(
    ["admin-users"],
    ({ signal }) => jsonGet(`/manage/user/admin_users/?page_size=100000`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useAdminUser = <TData = api.user.DetailedAdminUser,>(
  options?: UseQueryOptions<api.user.DetailedAdminUser, unknown, TData> & {
    params?: { adminUserUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { adminUserUuid } = useParams(options?.params)
  return useQuery(
    ["admin-users", adminUserUuid],
    ({ signal }) => jsonGet(`/manage/user/admin_users/${adminUserUuid}/`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useAdminMe = <TData = api.user.SelfAdmin,>(
  options?: UseQueryOptions<api.user.SelfAdmin, unknown, TData>
) => {
  const { jsonGet } = useApi("user")
  return useQuery(
    ["admin-users", "me"],
    ({ signal }) => jsonGet("/manage/user/admin_users/me/", { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUpdateAdminUser = (
  options?: UseMutationOptions<
    api.user.DetailedAdminUser,
    unknown,
    DeepPartial<api.user.DetailedAdminUser>
  > & {
    params?: { adminUserUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { adminUserUuid } = useParams(options?.params)
  return useMutation(data => jsonPatch(`/manage/user/admin_users/${adminUserUuid}/`, data), {
    onSuccess: async data => {
      queryClient.setQueryData(["admin-users", adminUserUuid], data)
      queryClient.removeQueries(["admin-users"], { exact: true })
    },
    ...options,
  })
}

export const useDeleteAdminUser = (
  options?: UseMutationOptions & { params?: { adminUserUuid?: string } }
) => {
  const { jsonDelete } = useApi("user")
  const { adminUserUuid } = useParams(options?.params)
  return useMutation(() => jsonDelete(`/manage/user/admin_users/${adminUserUuid}/`), {
    onSuccess: async () => {
      await queryClient.refetchQueries(["admin-users"], { exact: true })
      setTimeout(() => queryClient.removeQueries(["admin-users", adminUserUuid]), 1000)
    },
    ...options,
  })
}
