export class AppError extends Error {
  constructor(message: string) {
    super(message)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppError)
    }

    this.name = "AppError"
  }
}

export class AccessDeniedError extends AppError {
  constructor(message: string) {
    super(message)
    this.name = "AccessDeniedError"
  }
}
export class NotFoundError extends AppError {
  constructor() {
    super("Page not found")
    this.name = "NotFoundError"
  }
}
