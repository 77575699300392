import { captureException, withScope } from "@sentry/browser"
import { decode, encode, JavascriptObject, JSONValue } from "./jsonCodec"

export const baseUrlByApi = {
  analytics: `${import.meta.env.REACT_APP_API_URL || "/api/analytics"}/1.0`,
  billing: `${import.meta.env.REACT_APP_API_URL || "/api/billing"}/1.0`,
  ott: `${import.meta.env.REACT_APP_API_URL || "/api/ott"}/1.0`,
  upload: `${import.meta.env.REACT_APP_API_URL || "/api/upload"}/1.0`,
  user: `${import.meta.env.REACT_APP_API_URL || "/api/user"}/1.0`,
}

export type ApiType = keyof typeof baseUrlByApi
export type Headers = { [key: string]: string }

type MethodType = "GET" | "PUT" | "PATCH" | "POST" | "DELETE"

export class ApiError extends Error {
  method: MethodType
  uri: string
  status: number
  jsonData: JSONValue

  constructor(api: ApiType, method: MethodType, uri: string, status: number, jsonData: JSONValue) {
    super(`API error ${api}`)
    this.method = method
    this.uri = uri
    this.status = status
    this.jsonData = jsonData
  }
}

const handleKoResponse = async (
  method: MethodType,
  api: ApiType,
  uri: string,
  requestId: string,
  response: Response
) => {
  const jsonData =
    response.headers.get("Content-Type") === "application/json"
      ? decode(await response.text())
      : null
  const error = new ApiError(api, method, uri, response.status, jsonData)

  if (response.status >= 500) {
    withScope(scope => {
      scope.setTag("request-api", api)
      scope.setTag("request-id", requestId)
      scope.setTag("request-method", method)
      scope.setTag("request-status", response.status)
      scope.setTag("request-uri", uri)
      scope.setFingerprint(["api-error", api])
      captureException(error)
    })
  }

  throw error
}

export const generateUniqueId = (length = 16) =>
  window
    .btoa(
      Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
        .map(b => String.fromCharCode(b))
        .join("")
    )
    .replace(/[+/]/g, "")
    .substring(0, length)

export const jsonGet = async <TData>(
  uri: string,
  token: string,
  api: ApiType,
  options: { headers?: Headers; signal?: AbortSignal } = {}
): Promise<TData> => {
  const requestId = generateUniqueId()
  const baseUrl = baseUrlByApi[api]
  const url = baseUrl + uri

  const response = await fetch(url, {
    headers: {
      ...options.headers,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "request-id": requestId,
    },
    mode: "cors",
    signal: options.signal,
  })

  if (!response.ok) {
    await handleKoResponse("GET", api, uri, requestId, response)
  }

  return decode(await response.text()) as any
}

export const jsonPatch = async <TData>(
  uri: string,
  body: JavascriptObject,
  token: string,
  headers: Headers,
  api: ApiType
): Promise<TData> => {
  const requestId = generateUniqueId()
  const baseUrl = baseUrlByApi[api]
  const url = baseUrl + uri

  const response = await fetch(url, {
    body: encode(body),
    headers: {
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "request-id": requestId,
    },
    method: "PATCH",
    mode: "cors",
  })

  if (!response.ok) {
    await handleKoResponse("PATCH", api, uri, requestId, response)
  }

  return decode(await response.text()) as any
}

export const jsonPut = async <TData>(
  uri: string,
  body: JavascriptObject,
  token: string,
  headers: Headers,
  api: ApiType
): Promise<TData> => {
  const requestId = generateUniqueId()
  const baseUrl = baseUrlByApi[api]
  const url = baseUrl + uri

  const response = await fetch(url, {
    body: encode(body),
    headers: {
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "request-id": requestId,
    },
    method: "PUT",
    mode: "cors",
  })

  if (!response.ok) {
    await handleKoResponse("PUT", api, uri, requestId, response)
  }

  return decode(await response.text()) as any
}

export const jsonPost = async <TData>(
  uri: string,
  body: JavascriptObject,
  token: string,
  headers: Headers,
  api: ApiType
): Promise<TData> => {
  const requestId = generateUniqueId()
  const baseUrl = baseUrlByApi[api]
  const url = baseUrl + uri

  const response = await fetch(url, {
    body: encode(body),
    headers: {
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "request-id": requestId,
    },
    method: "POST",
    mode: "cors",
  })

  if (!response.ok) {
    await handleKoResponse("POST", api, uri, requestId, response)
  }

  return decode(await response.text()) as any
}

export const jsonDelete = async <TData>(
  uri: string,
  token: string,
  headers: Headers,
  api: ApiType
): Promise<TData> => {
  const requestId = generateUniqueId()
  const baseUrl = baseUrlByApi[api]
  const url = baseUrl + uri

  const response = await fetch(url, {
    headers: {
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "request-id": requestId,
    },
    method: "DELETE",
    mode: "cors",
  })

  if (!response.ok) {
    await handleKoResponse("DELETE", api, uri, requestId, response)
  }

  return decode((await response.text()) || "{}") as any
}

export const uploadFile = <TData>(
  uri: string,
  file: Blob,
  token: string,
  onProgress: (progressRate: number) => void
): Promise<TData> =>
  new Promise((resolve, reject) => {
    const api = "upload"
    const requestId = generateUniqueId()
    const data = new FormData()
    data.append("file", file)

    const baseUrl = baseUrlByApi[api]
    const url = baseUrl + uri

    const request = new XMLHttpRequest()
    request.open("POST", url)
    request.setRequestHeader("Accept", "application/json")
    request.setRequestHeader("Authorization", `Bearer ${token}`)
    request.setRequestHeader("request-id", requestId)

    if (onProgress) {
      request.upload.addEventListener("progress", function (e) {
        const progressRate = e.loaded / e.total
        onProgress(progressRate)
      })
    }

    request.addEventListener("load", () => {
      const { responseText, status } = request
      if (status < 200 || status >= 300) {
        const jsonData =
          request.getResponseHeader("Content-Type") === "application/json"
            ? decode(responseText)
            : null

        const error = new ApiError(api, "POST", uri, status, jsonData)

        if (status !== 400) {
          withScope(scope => {
            scope.setTag("request-api", api)
            scope.setTag("request-id", requestId)
            scope.setTag("request-method", "POST")
            scope.setTag("request-status", status)
            scope.setTag("request-uri", uri)
            scope.setFingerprint(["api-error", api])
            captureException(error)
          })
        }

        return reject(error)
      }
      resolve(decode(responseText || "{}") as any)
    })

    request.addEventListener("error", () => {
      reject(new Error("Failed to fetch"))
    })

    request.send(data)
  })
