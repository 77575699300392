import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { useParams } from "src/helpers/location"
import { useApi } from "src/hooks/api"
import { queryClient } from "./client"

export const useOrganizations = <TData = api.user.PaginatedOrganizationListList>(
  options?: UseQueryOptions<api.user.PaginatedOrganizationListList, unknown, TData>
) => {
  const { jsonGet } = useApi("user")
  return useQuery(
    ["orgs"],
    ({ signal }) => jsonGet("/organizations/", { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useOrganization = <TData = api.user.Organization>(
  options?: UseQueryOptions<api.user.Organization, unknown, TData> & {
    params?: { organizationUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { organizationUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid],
    ({ signal }) =>
      jsonGet(`/organizations/${organizationUuid}/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUpdateOrganization = (
  options?: UseMutationOptions<
    api.user.Organization,
    unknown,
    DeepPartial<api.user.Organization>
  > & {
    params?: { organizationUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { organizationUuid } = useParams(options?.params)
  return useMutation(
    data => jsonPatch(`/organizations/${organizationUuid}/?org=${organizationUuid}`, data),
    {
      onSuccess: async data => {
        queryClient.setQueryData(["orgs", organizationUuid], data)
      },
      ...options,
    }
  )
}

export const useValidateOrganization = (
  options?: UseMutationOptions<
    api.user.OrgValidation,
    unknown,
    DeepPartial<api.user.OrgValidation>
  > & {
    params?: { organizationUuid?: string }
  }
) => {
  const { jsonPost } = useApi("user")
  const { organizationUuid } = useParams(options?.params)
  return useMutation(
    data =>
      jsonPost(
        `/manage/user/organization/${organizationUuid}/validate/?org=${organizationUuid}`,
        data
      ),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["orgs"], { exact: true })
        await queryClient.refetchQueries(["orgs", organizationUuid], { exact: true })
      },
      ...options,
    }
  )
}

export const useDeleteOrganization = (
  options?: UseMutationOptions & { params?: { organizationUuid?: string } }
) => {
  const { jsonDelete } = useApi("user")
  const { organizationUuid } = useParams(options?.params)
  return useMutation(
    () =>
      jsonDelete(
        `/organizations/${organizationUuid}/?org=${organizationUuid}&org_check=${organizationUuid}`
      ),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["orgs"], { exact: true })
        setTimeout(() => queryClient.removeQueries(["orgs", organizationUuid]), 1000)
      },
      ...options,
    }
  )
}

//------------------------------------------------------------------------------
// Organization members
//------------------------------------------------------------------------------

export const useOrganizationMembers = <TData = api.user.PaginatedMemberList>(
  options?: UseQueryOptions<api.user.PaginatedMemberList, unknown, TData> & {
    params?: { organizationUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { organizationUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "members"],
    ({ signal }) => jsonGet(`/organization/members/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useOrganizationMember = <TData = api.user.Member>(
  options?: UseQueryOptions<api.user.Member, unknown, TData> & {
    params?: { organizationUuid?: string; userUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { organizationUuid, userUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "members", userUuid],
    ({ signal }) =>
      jsonGet(`/organization/members/${userUuid}/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useUpdateOrganizationMember = (
  options?: UseMutationOptions<api.user.Member, unknown, DeepPartial<api.user.Member>> & {
    params?: { organizationUuid?: string; userUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { organizationUuid, userUuid } = useParams(options?.params)
  return useMutation(
    data => jsonPatch(`/organization/members/${userUuid}/?org=${organizationUuid}`, data),
    {
      onSuccess: async data => {
        queryClient.setQueryData(["orgs", organizationUuid, "members", userUuid], data)
        await Promise.all([
          queryClient.refetchQueries(["orgs", organizationUuid, "members"], { exact: true }),
          queryClient.refetchQueries(["users", userUuid]),
        ])
      },
      ...options,
    }
  )
}

//------------------------------------------------------------------------------
// Organization API keys
//------------------------------------------------------------------------------

export const useApiKeys = <TData = api.user.PaginatedAdminApiKeyList>(
  options?: UseQueryOptions<api.user.PaginatedAdminApiKeyList, unknown, TData> & {
    params?: { apiKeyUuid?: string; organizationUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { organizationUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "apiKeys"],
    ({ signal }) => jsonGet(`/manage/user/api_key/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useApiKey = <TData = api.user.AdminDetailedApiKey>(
  options?: UseQueryOptions<api.user.AdminDetailedApiKey, unknown, TData> & {
    params?: { apiKeyUuid?: string; organizationUuid?: string }
  }
) => {
  const { jsonGet } = useApi("user")
  const { apiKeyUuid, organizationUuid } = useParams(options?.params)
  return useQuery(
    ["orgs", organizationUuid, "apiKeys", apiKeyUuid],
    ({ signal }) =>
      jsonGet(`/manage/user/api_key/${apiKeyUuid}/?org=${organizationUuid}`, { signal }),
    options as Omit<typeof options, "params"> // hack to help typescript resolve conflict
  )
}

export const useToogleBlockApiKey = (
  options?: UseMutationOptions<api.user.AdminDetailedApiKey, unknown, boolean> & {
    params?: { apiKeyUuid?: string; organizationUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { apiKeyUuid, organizationUuid } = useParams(options?.params)
  return useMutation(
    data =>
      jsonPatch(`/manage/user/api_key/${apiKeyUuid}/?org=${organizationUuid}`, { blocked: data }),
    {
      onSuccess: async data => {
        queryClient.setQueryData(["orgs", organizationUuid, "apiKeys", apiKeyUuid], data)
        await queryClient.refetchQueries(["orgs", organizationUuid, "apiKeys"], { exact: true })
      },
      ...options,
    }
  )
}

export const useToogleReadOnlyApiKey = (
  options?: UseMutationOptions<api.user.AdminDetailedApiKey, unknown, boolean> & {
    params?: { apiKeyUuid?: string; organizationUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { apiKeyUuid, organizationUuid } = useParams(options?.params)
  return useMutation(
    data =>
      jsonPatch(`/manage/user/api_key/${apiKeyUuid}/?org=${organizationUuid}`, { readOnly: data }),
    {
      onSuccess: async data => {
        queryClient.setQueryData(["orgs", organizationUuid, "apiKeys", apiKeyUuid], data)
        await queryClient.refetchQueries(["orgs", organizationUuid, "apiKeys"], { exact: true })
      },
      ...options,
    }
  )
}

export const useUpdateApiKey = (
  options?: UseMutationOptions<
    api.user.AdminDetailedApiKey,
    unknown,
    DeepPartial<api.user.AdminDetailedApiKey>
  > & {
    params?: { apiKeyUuid?: string; organizationUuid?: string }
  }
) => {
  const { jsonPatch } = useApi("user")
  const { apiKeyUuid, organizationUuid } = useParams(options?.params)
  return useMutation(
    async data => {
      await jsonPatch(`/organization/api_key/${apiKeyUuid}/?org=${organizationUuid}`, data)
      return await jsonPatch(`/manage/user/api_key/${apiKeyUuid}/?org=${organizationUuid}`, data)
    },
    {
      onSuccess: async data => {
        queryClient.setQueryData(["orgs", organizationUuid, "apiKeys", apiKeyUuid], data)
        await queryClient.refetchQueries(["orgs", organizationUuid, "apiKeys"], { exact: true })
      },
      ...options,
    }
  )
}

export const useDeleteApiKey = (
  options?: UseMutationOptions & { params?: { apiKeyUuid?: string; organizationUuid?: string } }
) => {
  const { jsonDelete } = useApi("user")
  const { apiKeyUuid, organizationUuid } = useParams(options?.params)
  return useMutation(
    () => jsonDelete(`/organization/api_key/${apiKeyUuid}/?org=${organizationUuid}`),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["orgs", organizationUuid, "apiKeys"], { exact: true })
        setTimeout(
          () => queryClient.removeQueries(["orgs", organizationUuid, "apiKeys", apiKeyUuid]),
          1000
        )
      },
      ...options,
    }
  )
}
